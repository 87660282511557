<template>
    <div class="fl-te-c py-2 bb-1 py-2 cursor-pointer p-2" @click="$emit('click')">
        <div class="ml-1">
            <span class="m-0 font-weight-medium">{{ orders.user_id.name }}</span>
            <p class="m-0 text-4" style="font-size: 13px;">{{ getDate(orders.created_at) }}</p>

        </div>
        <div class="fl-x fl-a-c">
            <span>&#8377;</span>
            <span class="ml-1 m-0 font-weight-medium">{{ orders.total_amount }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name  : 'nameCard',
    props : {
        orders : { type : Object }

    },
    methods : {
        getDate (datetime) {
            const current = new Date(datetime);
            const date = current.getDate() + '-' + ('0' + (current.getMonth() + 1)).slice(-2) + '-' + current.getFullYear();
            return date;
        }
    }
};
</script>

<style scoped>
.shadow {
    box-shadow: 0 4px 24px 0 rgb(0 0 0 / 20%);
}
</style>
