<template>
    <div>

        <div class="card text-center" v-if="loading">
            <loading-animation/>
            <h6 class="pl-2">Please wait while data is being loaded...</h6>
        </div>
        <div class="card text-center" v-else-if="error">

            <h6 class="pl-2">No Orders</h6>
        </div>
        <div v-else class="row">
            <div class="col-md-2 br-1 p-0">
                <div class="fl-te-c pb-3 pl-2 position-div ">
                    <h3 class="text-primary">Orders</h3>
                </div>
                <div>
                    <infinite-scroll-container @load-next="loadNext">
                        <div v-for="(order, index) in orders" v-bind:key="index"
                             class="position-div hover-bg-3"
                             :class="{'active':activeCategoryIdx===order.id}">
                            <name-card :orders="order" @click="FilterCat(order,index)"></name-card>

                        </div>
                    </infinite-scroll-container>
                </div>
            </div>
            <div class="col-md-10">
                <div class="card text-center" v-if="loadingSecond">
                    <loading-animation/>
                    <h6 class="pl-2">Please wait while data is being loaded...</h6>
                </div>
                <div v-else>
                    <order-details :order_id="order_id" :total_amount="total_amount"
                                   :firstOrder="FirstOrder"
                                   :userDetails="userDetails"
                                   :userAddress="userAddress" @update="setUpadte"></order-details>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NameCard from '@/views/orders/orderComponents/nameCard';
import OrderDetails from '@/views/orders/orderComponents/orderDetails';
import InfiniteScrollContainer from '@components/InfiniteScroll';
import debounce from 'lodash.debounce';
import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    name : 'orders',

    components : {
        OrderDetails,
        NameCard,
        InfiniteScrollContainer
    },
    data () {
        return {
            activeCategoryIdx : 0,
            id                : this.$route.params.id,
            user_id           : this.$route.params.user_id,
            address_id        : this.$route.params.address_id,
            error             : false,
            orders            : [],
            FirstOrder        : [],
            orderItems        : [],
            userDetails       : [],
            userAddress       : [],
            order_id          : '',
            total_amount      : '',
            page              : 1,
            isLast            : false,
            loading           : false,
            loadingSecond     : false,
            search            : '',
            model             : {
                name  : '',
                image : []

            },
            model1 : {
                order_id   : '',
                user_id    : '',
                show_first : true,
                status     : 'pending',
                address_id : ''
            }
        };
    },
    watch : {
        search : debounce(function () {
            this.orders = [];
            this.page = 0;
            this.isLast = false;
            this.loadNext();
        }, 500)
    },
    beforeMount () {
        this.loadFirstData();
        //  this.fetchOrderData();
    },
    methods : {
        // fetchOrderData () {
        //     if (this.id) {
        //         this.loadData(this.id, this.user_id, this.address_id);
        //     } else {
        //         this.loadData();
        //     }
        // },
        // viewDetails (item) {
        //     this.loadingSecond = true;
        //     this.activeCategoryIdx = item.id;
        //     this.loadData(item.id, item.user_id.id, item.address_id.id);
        // },
        // async loadData (id, userId, addressId) {
        //     if (id) {
        //         this.loading = false;
        //         this.loadingSecond = true;
        //         this.model1.show_first = false;
        //         this.model1.order_id = id;
        //         this.model1.user_id = userId;
        //         this.model1.address_id = addressId;
        //     } else {
        //         this.loading = true;
        //     }
        //     const response = await axios.get(urls.orders.orderList, {
        //         params : this.model1
        //     });
        //     if (response.data.status === true) {
        //         this.loading = false;
        //         this.loadingSecond = false;
        //         this.setData(response);
        //     } else {
        //         this.loading = false;
        //         this.error = true;
        //     }
        // },
        async FilterCat (item, index) {
            this.order_id = item.id;
            this.activeCategoryIdx = item.id;
            this.loading = false;
            this.loadingSecond = true;
            const response = await axios.get(urls.orders.orderList, {
                params : {
                    order_id : item.id
                }
            });
            this.loading = false;
            this.loadingSecond = false;
            if (response) {
                this.FirstOrder = response.data.orderItems;
                this.userDetails = this.orders.filter(el => el.id === this.order_id)[0].user_id;
                this.userAddress = this.orders.filter(el => el.id === this.order_id)[0].address_id;
            }
        },
        async loadNext () {
            if (this.loading) {
                return;
            }
            if (this.isLast) {
                return;
            }
            this.page += 1;
            await this.loadScrollData();
        },
        async loadScrollData () {
            this.loadingSecond = false;
            const response = await axios.get(urls.orders.orderList, {
                params : {
                    status   : 'pending',
                    search   : this.search,
                    page     : this.page,
                    per_page : 10
                }
            });
            this.loading = false;
            if (response) {
                if (response.data.data.length > 0) {
                    this.orders.push(...response.data.data);
                }
            }
        },
        async loadFirstData () {
            this.loading = true;
            const response = await axios.get(urls.orders.orderList, {
                params : {
                    show_first : true,
                    status     : 'pending'
                }
            });
            if (response.data.status === true) {
                this.loading = false;
                this.setData(response);
            } else {
                this.loading = false;
                this.error = true;
            }
        },
        setData (response) {
            if (response.data) {
                this.orders = [...response.data.data];
                this.FirstOrder = response.data.orderItems;
                this.order_id = response.data.orderItems.total_amount[0].id;
                this.activeCategoryIdx = this.order_id;
                this.userDetails = this.orders.filter(el => el.id === this.order_id)[0].user_id;
                this.userAddress = this.orders.filter(el => el.id === this.order_id)[0].address_id;
            }
        },

        setUpadte () {
            this.loadFirstData();
        }

    }
};
</script>

<style scoped>

</style>
