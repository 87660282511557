<template>
    <div>
        <div class="row">
            <h3 class="col">Order <span class="text-primary"> #{{ firstOrder.data[0].order_id }}</span></h3>
            <div class="col fl-x-tr">
                <btn color="primary" @click="DeliverystatusUpdate(order_id)">Delivered</btn>
                <btn color="danger" class="ml-1" @click="CancelOrders(order_id)">Cancel</btn>
            </div>
        </div>
        <div>
            <div class="mt-3 row">
                <!--            <div class="card shadow" v-for="(customer,key) in userDetails" v-bind:key="key">-->
                <!--                <h6>Customer details</h6>-->
                <!--                <p>{{customer.name}}</p>-->
                <!--                <p>{{customer.mobile_number}}</p>-->
                <!--            </div>-->

                <div class="col-4">
                    <div class="card shadow min-height">
                        <h6 class="fl-x mb-2">
                            <i class="bl-8 mr-2 b-primary" aria-hidden="true"></i>
                            <span>Customer details</span></h6>
                        <h2 class="m-1">{{ userDetails.name }}</h2>
                        <p class="m-1">
                            <i class="fa fa-phone text-primary" aria-hidden="true"></i>
                            <span>{{ userDetails.mobile_number }}</span>
                        </p>
                    </div>
                </div>
                <div class="col-4">
                    <div class="card shadow min-height">
                        <h6 class="fl-x mb-2">
                            <i class="bl-8 mr-2 b-primary" aria-hidden="true"></i>
                            <span>Order details</span></h6>
                        <!--                <p v-for="(i, key) in firstOrder.data" v-bind:key="key">-->

                        <p class="m-1"><i class="fa fa-money text-primary" aria-hidden="true"></i>
                            <span>&#8377;{{ firstOrder.total_amount[0].total_amount }}</span>
                        </p>
                        <p class="m-1">
                            <i class="fa fa-clock-o text-primary" aria-hidden="true"></i>
                            <!--                            <span> {{-->
                            <!--                                    new Date(firstOrder.data[0].created_at).toJSON().slice(0, 10).replace(/-/g, '-')-->
                            <!--                                }}</span>-->

                            <span>{{ getDate(firstOrder.data[0].date) }}</span>
                        </p>
                    </div>
                </div>
                <div class="col-4">
                    <div class="card shadow min-height">
                        <h6 class="fl-x mb-2">
                            <i class="bl-8 mr-2 b-primary" aria-hidden="true"></i>
                            <span> Delivery address</span></h6>
                        <div class="fl-x">
                            <i class="fa fa-map-marker text-primary"></i>
                            <p class="fl-eqh-l">
                                <span>{{ userAddress.address_line_1 }}</span>
                                <span>{{ userAddress.address_line_2 }}</span>
                                <span>{{ userAddress.landmark }}</span>
                                <span>{{ userAddress.pin_code }}</span>
                            </p>

                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="card mt-3 shadow col-12">
            <simple-table :fields="fields" :data="firstOrder.data">
                <template #image="{rowData}">
                    <div slot="image" class="fl-x fl-a-c">
                        <img :src="'https://microcommerce.alocart.in/upload/'+rowData.image" width="50" height="50"
                             alt="">
                        <span class="p-2">{{ rowData.name }}</span>
                    </div>
                </template>
            </simple-table>

        </div>
        <div>
            <p class="text-primary pt-3 px-6 text-right">Total &#8377;<span class="p-0 font-weight-bold"
                                                                            style="font-size: 20px">
                {{ firstOrder.total_amount[0].total_amount }}</span></p>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    name  : 'orderDetails',
    props : {
        data       : { type : Array },
        firstOrder : {
            type     : Object,
            required : false
        },
        order_id : {
            type     : String,
            required : false
        },
        total_amount : {
            type     : Number,
            required : false
        },
        ordersItems : { type : Object },
        userDetails : { type : Array },
        userAddress : { type : Array }
    },
    methods : {
        async DeliverystatusUpdate (orderid) {
            const that = this;
            this.loading = true;
            const response = await axios.form(urls.orders.status, {
                order_id : orderid,
                status   : 'delivered'
            });
            this.loading = false;
            const json = response.data;
            console.log('json', json);
            if (json.success === true) {
                that.$notify('Delivery Status Updated Successfully', 'Success', {
                    type : 'success'
                });
                that.$emit('update');
                this.loading = false;
            }
        },
        async CancelOrders (orderid) {
            this.loading = true;
            const response = await axios.form(urls.orders.cancel, {
                order_id : orderid
            });
            this.loading = false;
            const json = response.data;
            console.log('json', json);
            if (json.success === true) {
                this.$notify('Cancel order successfully', 'Success', {
                    type : 'success'
                });
                this.$emit('update');
                this.loading = false;
            }
        },
        getDate (datetime) {
            // const date = new Date(datetime).toJSON().slice(0, 10).replace(/-/g, '-');
            // console.log('date', date);
            // alert(date);
            // return date;
            const current = new Date(datetime);
            const longMonth = current.toLocaleString('en-us', { month : 'long' });
            const date = current.getDate() + ' ' + longMonth + ' ' + current.getFullYear();
            const time = current.getHours() + ':' + (current.getMinutes() < 10 ? '0' : '') + current.getMinutes();
            let time1 = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
            if (time1.length > 1) { // If time format correct
                time1 = time1.slice(1); // Remove full string match value
                time1[5] = +time1[0] < 12 ? 'am' : 'pm'; // Set AM/PM
                time1[3] = ' ';
                time1[0] = +time1[0] % 12 || 12; // Adjust hours
            }
            time1 = time1.join('');
            console.log('time1', time1);
            const dateTime = time1 + ', ' + date;

            return dateTime;
        }
    },

    data () {
        return {
            fields : [
                {
                    name       : 'slno',
                    sortField  : '',
                    title      : 'SL NO.',
                    titleClass : ''
                },
                {
                    name  : '__slot:image',
                    title : 'Product'
                },
                // {
                //     name       : 'name',
                //     sortField  : '',
                //     title      : 'Product',
                //     titleClass : ''
                // },

                {
                    name       : 'quantity',
                    sortField  : '',
                    title      : 'Quantity',
                    titleClass : ''
                },
                {
                    name       : 'selling_price',
                    sortField  : '',
                    title      : 'Rate',
                    titleClass : ''
                },
                {
                    name       : 'total_price',
                    sortField  : '',
                    title      : 'Total',
                    titleClass : ''
                }
            ]
        };
    }
};
</script>

<style scoped>
.shadow {
    box-shadow: 0 4px 24px 0 rgb(0 0 0 / 20%);
}
</style>
